import React from 'react';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from '@material-ui/core';
// import Loading from './Loading';
import styles from './KeyVisual.module.scss';
import ScrollCtaSvg from '../../images/10th_anniversary/scroll-cta.svg';
import AppStoreImg from '../../images/10th_anniversary/appStoreBadge.png';
import PlayStoreImg from '../../images/10th_anniversary/playStoreBadge.png';
import { BadgesByDevice } from '..';

const APP_URLS_SP = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/PaidyAnniversary',
  playStoreUrl: 'https://paidy.onelink.me/GTiS/PaidyAnniversary',
};

const APP_URLS_PC = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/PaidyAnniversary',
  playStoreUrl: 'https://paidy.onelink.me/GTiS/PaidyAnniversaryAndroidPC',
};

const iframeStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const renderIframe = (src) => (
  <iframe
    id="keyVisualAnimation"
    src={src}
    title="10th Anniversary Animation"
    width="100%"
    height="100%"
    style={iframeStyle}
    allow="fullscreen"
    // onLoad={onLoad}
  />
);

const KeyVisual = React.memo(({ id }) => {
  const isDesktop = useMediaQuery('(min-width:769px)');
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const hasVisited = localStorage.getItem('hasVisited10thAnniversary');

  //   if (!hasVisited) {
  //     setIsLoading(true);
  //     localStorage.setItem('hasVisited10thAnniversary', 'true');
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, []);

  // const handleIframeLoad = () => {
  //   setIsLoading(false);
  // };

  return (
    <>
      <Helmet>
        <link
          rel="preconnect"
          href="https://my.spline.design"
          crossOrigin="anonymous"
        />
      </Helmet>
      {/* {isLoading && <Loading />} */}
      <section className={styles.keyVisual} id={id}>
        <div className={styles.keyVisualAnimationWrapper}>
          {isDesktop
            ? renderIframe('https://my.spline.design/V5LBB9BfwrLw3iPdjP3TWGqK/')
            : renderIframe(
                'https://my.spline.design/WHCcjkQJBgs1fVKVzBzbqveb/'
              )}
        </div>
        <div className={styles.keyVisualContentWrapper}>
          <div className={styles.keyVisualContentPlaceholder} />
          <div className={styles.keyVisualContent}>
            <div className={styles.keyVisualContentText}>
              <>
                <h1 className={styles.showDesktop}>
                  そのお買い物が夢に 繋がりますように｡
                </h1>
                <h1 className={styles.showMobile}>
                  そのお買い物が、
                  <br /> 夢に繋がっていますように｡
                </h1>

                <div className={styles.keyVisualContentTextDescription}>
                  <p className={styles.showDesktop}>
                    最近、どんなお買い物をしましたか？ <br />
                    小さなものでも、大きなものでも、それは夢への一歩。 <br />
                    したい、なりたい、を叶えるものです。 <br />
                    その想いを応援するために、 <br />
                    今ある面倒なお支払いのルールなんて変えてしまおう。 <br />
                    そんな想いから生まれたのが、ペイディです。 <br />
                    この1 0 年、私たちのサービスを信じて利用してくださった皆様。{' '}
                    <br />
                    本当にありがとうございました。 <br />
                    これからも、夢へと近づく誰かの背中を押せるよう、 <br />
                    お買い物の未来を創造していきます。
                  </p>
                  <p className={styles.showMobile}>
                    最近、どんなお買い物をしましたか？
                    <br />
                    小さなものでも、大きなものでも、
                    <br />
                    それは夢への一歩。
                    <br />
                    したい、なりたい、を叶えるものです。
                    <br />
                    その想いを応援するために、
                    <br />
                    今ある面倒なお支払いのルールなんて
                    <br />
                    変えてしまおう。
                    <br />
                    そんな想いから生まれたのが、ペイディです。
                    <br />
                    この1 0 年、私たちのサービスを信じて
                    <br />
                    利用してくださった皆様。
                    <br />
                    本当にありがとうございました。
                    <br />
                    これからも、
                    <br />
                    夢へと近づく誰かの背中を押せるよう、
                    <br />
                    お買い物の未来を創造していきます。
                  </p>
                </div>
                <img
                  className={styles.showDesktop}
                  src={ScrollCtaSvg}
                  width={12}
                  height={83}
                  alt="Scroll CTA"
                />
              </>
            </div>
            <div className={styles.keyVisualContentButton}>
              <BadgesByDevice
                appUrlsSp={APP_URLS_SP}
                appUrlsPc={APP_URLS_PC}
                className={styles.keyVisualContentButtonBadges}
                appStoreImg={AppStoreImg}
                playStoreImg={PlayStoreImg}
                appStoreWidth={109}
                appStoreHeight={40}
                playStoreWidth={135}
                playStoreHeight={40}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default KeyVisual;
