import { useState, useCallback, useEffect } from 'react';
import throttle from 'lodash.throttle';

const useScrollAndSetActiveSection = (navs) => {
  const [activeSection, setActiveSection] = useState(navs[0]);

  const onScroll = useCallback(() => {
    if (!document) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax, no-plusplus
    for (let i = 0; i < navs.length; i++) {
      const nav = navs[i];
      const rectElement = document.getElementById(nav);

      if (rectElement) {
        const rect = rectElement.getBoundingClientRect();
        const isOnScreen = rect.top <= 64 && rect.bottom >= 64;

        if (isOnScreen) {
          setActiveSection(nav);
        }
      }
    }
  }, [navs]);

  useEffect(() => {
    const throttledScroll = throttle(onScroll, 200);

    if (window) {
      window.addEventListener('scroll', throttledScroll, true);
    }

    return () => {
      if (window) {
        window.removeEventListener('scroll', throttledScroll);
      }
    };
  }, [onScroll]);

  return activeSection;
};

export default useScrollAndSetActiveSection;
