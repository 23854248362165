import React from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';
import { Link } from 'gatsby';
import useScrollAndSetActiveSection from '../hooks/useScrollAndSetActiveSection';
import { SITE_METADATA, PAIDY_HOST } from '../constants';
import StrokeSvg from '../images/10th_anniversary/stroke.svg';
import StrokeWhiteSvg from '../images/10th_anniversary/stroke-white.svg';
import styles from '../styles/pages/10th_anniversary.module.scss';
import { Layout } from '../components';
import Header from '../components/10thAnniversary/Header';
import KeyVisual from '../components/10thAnniversary/KeyVisual';

const SectionWrapper = loadable(() =>
  import('../components/10thAnniversary/SectionWrapper')
);
const Section1 = loadable(() =>
  import('../components/10thAnniversary/Section1')
);
const Section2 = loadable(() =>
  import('../components/10thAnniversary/Section2')
);
const Section3 = loadable(() =>
  import('../components/10thAnniversary/Section3')
);

const SEOProps = {
  ogImage: `${PAIDY_HOST}/ogp_10th_anniversary.png`,
  title: SITE_METADATA['10th_anniversary'].title,
  description: SITE_METADATA['10th_anniversary'].description,
};

const NAVS = ['お買い物データ', 'みんなの夢', 'キャンペーン'];

export default function Campaign202303() {
  const activeSection = useScrollAndSetActiveSection(NAVS);

  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <div className={styles.container}>
        <Header />
        <KeyVisual id="トップ" />
        <nav className={styles.nav}>
          <div className={styles.navWrapper}>
            {NAVS.map((nav) => (
              <div
                key={nav}
                className={classNames(styles.navItem, {
                  [styles.navItemActive]: nav === activeSection,
                  [styles.navItemHighLight]: nav === 'キャンペーン',
                })}
              >
                <Link to={`#${nav}`}>{nav}</Link>
              </div>
            ))}
          </div>
        </nav>
        <SectionWrapper
          id="お買い物データ"
          sectionNumber="01"
          sectionTitle="お買い物データ"
          strokeSvg={StrokeSvg}
          className={styles.section1}
        >
          <Section1 />
        </SectionWrapper>
        <SectionWrapper
          id="みんなの夢"
          sectionNumber="02"
          sectionTitle="お買い物で叶えたみんなの夢"
          sectionDescription={
            <>
              <span>ペイディで夢を叶えた</span> <br />
              <span>皆さんの声をご紹介します。</span>
            </>
          }
          strokeSvg={StrokeWhiteSvg}
          className={styles.section2}
          classNameHeader={styles.sectionHeader2}
        >
          <Section2 />
        </SectionWrapper>
        <div className={styles.section3Wrapper}>
          <div className={styles.section3}>
            <div className={styles.section3Background}>
              <iframe
                title="Spline 3D Design"
                src="https://my.spline.design/hals0Ah51xBVkRgpDflA5zSa/"
                width="100%"
                height="100%"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                allow="fullscreen"
              />
            </div>
            <div className={styles.section3Content}>
              <SectionWrapper
                id="キャンペーン"
                sectionNumber="03"
                sectionTitle="10周年記念キャンペーン"
                sectionDescription={
                  <>
                    <span>
                      10年分の感謝の気持ちを込めて、お得なキャンペーンを開催します。
                    </span>{' '}
                    <br />
                    <span>どうぞ、ご参加ください。</span>
                  </>
                }
                strokeSvg={StrokeSvg}
              >
                <Section3 />
              </SectionWrapper>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
