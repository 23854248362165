import React from 'react';
import { Layout } from 'src/components';
import BannerImg from 'src/images/npay/banner.png';
import BannerSpImg from 'src/images/npay/banner-sp.png';
import UpsellImg from 'src/images/npay/upsell.png';
import ThreePayImg from 'src/images/npay/3pay.png';
import { SITE_METADATA } from 'src/constants';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from 'src/styles/pages/npay.module.scss';
import { Hidden } from '@material-ui/core';
import MerchantsGridWithAccordion from 'src/components/MerchantsGridWithAccordion';

const SEOProps = {
  title: SITE_METADATA.npay.title,
  description: SITE_METADATA.npay.description,
  keywords: SITE_METADATA.npay.keywords,
};

export default function NPay() {
  return (
    <Layout SEOProps={SEOProps}>
      <section className={styles.header}>
        <h1 className={styles.title}>分割あと払い</h1>
        <Hidden xsDown>
          <img
            className={styles.banner}
            alt="banner"
            src={BannerImg}
            width={1407}
            height={477}
          />
        </Hidden>
        <Hidden smUp>
          <img className={styles.banner} alt="banner-sp" src={BannerSpImg} />
        </Hidden>
      </section>
      <section className={styles.threePay}>
        <h1 className={styles.title}>
          分割手数料無料*の3回あと払い
          <Hidden smUp>
            <br />
          </Hidden>
          で月々のお支払いを調整
        </h1>
        <img alt="3pay" src={ThreePayImg} width={375} height={304} />
        <p className={styles.text}>
          分割手数料無料*で、お支払いを3回に分けることができます。お支払い総額はそのままで、月々のお支払いを調整できるので、よりかしこくお買い物を楽しめます。
          <br />
          <br />
          一括払いで購入後、ペイディアプリから3回あと払いに変更することも可能です
        </p>
        <LinearCTAButton
          className="ga_click_npay_learn_more_button_3pay"
          to="https://paidy.com/guide#3pay"
          text="もっと詳しく"
        />
      </section>
      <section className={styles.amazon}>
        <h1 className={styles.title}>
          6・12回あと払いも
          <Hidden smUp>
            <br />
          </Hidden>
          分割手数料無料*
        </h1>
        <img alt="amazon" src={UpsellImg} width={375} height={304} />
        <p className={styles.text}>
          さらに一部のお店では、6・12回あと払いもご利用いただけます。もちろん、分割手数料は無料*。あなたのライフスタイルにあわせて、お支払い回数を自由に選べます。
          <br />
          <br />
          一括払いで購入後、ペイディアプリから6・12回あと払いに変更することも可能です。
        </p>
        <LinearCTAButton
          className="ga_click_npay_learn_more_button_3pay"
          to="https://paidy.com/guide#3pay"
          text="もっと詳しく"
        />
        <h2 className={styles.gridTitle} id="title-merchants">
          6・12回あと払いが使えるおすすめのお店
        </h2>
        <MerchantsGridWithAccordion noButtonBottom />
        <p className={styles.notes}>
          *口座振替・銀行振込のみ無料
          <br />
          <br />
          ※選択できる支払い回数は加盟店により異なります。
          <br />
          <br />
          ※３回あと払いは1回のお買い物につき、Amazonは3円以上、その他のショップでは3,000円以上のお支払いで利用可能です。6回あと払いは1回のお買い物につき、Amazonは6円以上、その他のショップでは6,000円以上のお支払いで利用可能です。12回あと払いは1回のお買い物につき、12,000円以上のお支払いで利用可能です。
          <br />
          <br />
          ※ペイディカードで３回あと払いを利用する場合は、店舗での購入時に分割払いを選択せず、一括払いで購入後、ペイディアプリから変更してください。
          <br />
          <br />※ 使える加盟店は今後変更になる可能性があります。
        </p>
      </section>
    </Layout>
  );
}
