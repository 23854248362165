/* eslint-disable import/no-extraneous-dependencies */
export const MERCHANTS = {
  buyma: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-buyma.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  rakuten: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-rakuten.png'),
    destinationURL: 'https://fril.jp/',
  },
  st: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/st.png'),
    destinationURL:
      'https://www.dot-st.com/?utm_source=paidy&utm_medium=twitter&utm_campaign=20250127',
  },
  '2nd-street': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/2nd-street.png'),
    destinationURL: 'https://www.2ndstreet.jp/buy',
  },
  thesupersport: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/thesupersport.png'),
    destinationURL: 'https://www.supersports.com/ja-jp/xebio/',
  },
  '60percent': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-60percent.png'),
    destinationURL: 'https://www.sixty-percent.com/women',
  },
  dreamvs: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/dreamvs.png'),
    destinationURL: 'https://dreamvs.jp/',
  },
  'atmos-blue': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-atmos-blue.png'),
    destinationURL: 'https://www.atmos-tokyo.com/',
  },
  yz: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-yz.png'),
    destinationURL: 'https://yz-store.com/',
  },
  ur: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-ur.png'),
    destinationURL: 'http://www.urban-research.jp/',
  },
  newera: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-newera.png'),
    destinationURL: 'https://www.neweracap.jp/',
  },
  'ralph-lauren': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-ralph-lauren.png'),
    destinationURL: 'http://www.ralphlauren.co.jp/',
  },
  'taylor-made': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-taylor-made.png'),
    destinationURL:
      'https://www.taylormadegolf.jp/home?utm_source=Paidy&utm_medium=CP&utm_campaign=0127-0225',
  },
  morecontact: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-morecontact.png'),
    destinationURL: 'https://morecon.jp/',
  },
  morecos: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-morecos.png'),
    destinationURL: 'https://morecos.hmv.co.jp/',
  },
  hoshino: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-hoshino.png'),
    destinationURL: 'https://hoshinoresorts.com/jp/',
  },
  sakura: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-sakura.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
  },
  asoview: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-asoview.png'),
    destinationURL: 'https://www.asoview.com/',
  },
  airtrip: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/airtrip.png'),
    destinationURL:
      'https://www.airtrip.jp/?utm_source=airtrip.jp&utm_medium=web&utm_campaign=paidy_campaign_from20250127',
  },
  spacemarket: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-spacemarket.png'),
    destinationURL: 'https://www.spacemarket.com/',
  },
  busbookmark: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/busbookmark.png'),
    destinationURL: 'https://www.busbookmark.jp/',
  },
  dmm: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dmm.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  dlsite: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dlsite.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
  },
  hololive: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-hololive.png'),
    destinationURL: 'https://shop.hololivepro.com/',
  },
  soundhouse: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-soundhouse.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  i7live: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-i7live.png'),
    destinationURL: 'https://jp.17.live/',
  },
  hmvbooks: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/hmvbooks.png'),
    destinationURL: 'https://www.hmv.co.jp/',
  },
  kojima: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-kojima.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  geo: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/geo.png'),
    destinationURL: 'https://ec.geo-online.co.jp/shop/',
  },
  bicamera: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-bicamera.png'),
    destinationURL: 'https://www.biccamera.com/bc/c/sale/daily/index.jsp',
  },
  franc: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-franc.png'),
    destinationURL: 'https://francfranc.com/',
  },
  yamada: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-yamada.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  iris: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-iris.png'),
    destinationURL:
      'https://www.irisplaza.co.jp/index.php?&utm_source=paidy&utm_medium=Referral&utm_campaign=paidy_2409&utm_content=paidy_top',
  },
  kurand: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/kurand.png'),
    destinationURL:
      'https://kurand.jp/?utm_source=klaviyo&utm_medium=ec__paidy&utm_campaign=27805___',
  },
  mashstore: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/mashstore.png'),
    destinationURL: 'https://app.mashstore.jp/qxSO/egawja73',
  },
  gelatopique: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/gelatopique.png'),
    destinationURL:
      'https://gelatopique.com/?utm_source=refferal&utm_medium=social&utm_campaign=Paidy_CAMPAIGN',
  },
  drmartens: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/drmartens.png'),
    destinationURL: 'https://jp.drmartens.com/',
  },
  snidel: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/snidel.png'),
    destinationURL:
      'https://snidel.com/?utm_source=refferal&utm_medium=social&utm_campaign=Paidy_CAMPAIGN',
  },
  'casio-online-store': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/casio-online-store.png'),
    destinationURL:
      'https://www.casio.com/jp/online-store/?utm_source=paidy&utm_medium=referral&utm_campaign=onlinestore_paidycashbackcampaign_202501_jp&utm_content=%7C%7C%7C250115%7C%7C%7C%7C%7C',
  },
  vipliner: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/vipliner.png'),
    destinationURL: 'https://vipliner.biz/',
  },
  aviot: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/aviot.png'),
    destinationURL: 'https://shop.aviot.jp',
  },
  renta: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/renta.png'),
    destinationURL: 'http://renta.papy.co.jp/',
  },
  ships: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/ships.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  'golf-partner': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-golf-partner.png'),
    destinationURL: 'https://www.golfpartner.jp/shop/',
  },
  murasakisports: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/murasaki-sports.png'),
    destinationURL: 'https://www.murasaki.jp/Page/Feature/FeaturePage248.aspx',
  },
  fredperry: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/fred-perry.png'),
    destinationURL: 'https://www.fredperry.jp/',
  },
  ca4la: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/ca4la.png'),
    destinationURL: 'https://www.ca4la.com/',
  },
  lavenham: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/lavenham.png'),
    destinationURL: 'https://jp.lavenhamjackets.com/jp/',
  },
  jackman: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/jackman.png'),
    destinationURL: 'https://www.jackman.jp/',
  },
  narifuri: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/narifuri.png'),
    destinationURL: 'https://www.narifuri.com/',
  },
  green: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-green.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2501',
  },
  'bruno-online-store': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/bruno-online.png'),
    destinationURL: 'https://bruno-onlineshop.com/',
  },
  'airtrip-train': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/airtrip-train.png'),
    destinationURL: 'https://train.airtrip.jp/?cid=airtrip.paidy.campaign',
  },
  tigerair: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/tigerair.png'),
    destinationURL:
      'https://www.tigerairtw.com/zh-tw/?utm_source=paidy&utm_medium=landingpage&utm_campaign=paidylottery',
  },
  sorahapi: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-sorahapi.png'),
    destinationURL: 'https://www.sorahapi.jp/',
  },
  travelist: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-travelist.png'),
    destinationURL: 'https://travelist.jp/',
  },
  'dwang-ticket': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dwang.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  'airtrip-hotel': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/airtrip-hotel.png'),
    destinationURL:
      'https://domhotel.airtrip.jp/?utm_source=airtrip.jp&utm_medium=web&utm_campaign=paidy_cb_campaign_from20250127',
  },
  whitebearfamily: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/whitebearfamily.png'),
    destinationURL: 'https://www.wbf.co.jp/kokunai/',
  },
  'pre-bandai': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-pre-bandai.png'),
    destinationURL: 'https://p-bandai.jp/',
  },
  magi: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/magi.png'),
    destinationURL: 'https://magi.camp/',
  },
  rockon: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/rockon.png'),
    destinationURL: 'https://store.miroc.co.jp/',
  },
  booklive: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/booklive.png'),
    destinationURL: 'https://booklive.jp/',
  },
  orikuji: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/orikuji.png'),
    destinationURL: 'https://orikuji.com/',
  },
  rasik: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-rasik.png'),
    destinationURL: 'https://rasik.style/',
  },
  tsukumo: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-tsukumo.png'),
    destinationURL: 'http://shop.tsukumo.co.jp/',
  },
  tansu: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/tansu.png'),
    destinationURL: 'https://www.tansu-gen.jp/',
  },
  emma: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-emma.png'),
    destinationURL: 'https://www.emma-sleep-japan.com/',
  },
  xserver: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/xserver.png'),
    destinationURL: 'https://games.xserver.ne.jp/',
  },
  geomobile: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/geomobile.png'),
    destinationURL: 'https://mvno.geo-mobile.jp/uqmobile/',
  },
};

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchants: [
      MERCHANTS.buyma,
      MERCHANTS.rakuten,
      MERCHANTS.st,
      MERCHANTS['2nd-street'],
      MERCHANTS.ur,
      MERCHANTS['atmos-blue'],
      MERCHANTS.yz,
      MERCHANTS.newera,
      MERCHANTS['ralph-lauren'],
      MERCHANTS.thesupersport,
      MERCHANTS['60percent'],
      MERCHANTS.drmartens,
      MERCHANTS.gelatopique,
      MERCHANTS.dreamvs,
      MERCHANTS['taylor-made'],
      MERCHANTS['casio-online-store'],
      MERCHANTS.ships,
      MERCHANTS.mashstore,
      MERCHANTS.snidel,
      MERCHANTS['golf-partner'],
      MERCHANTS.murasakisports,
      MERCHANTS.fredperry,
      MERCHANTS.ca4la,
      MERCHANTS.lavenham,
      MERCHANTS.jackman,
      MERCHANTS.narifuri,
      MERCHANTS.morecontact,
      MERCHANTS.green,
      MERCHANTS['bruno-online-store'],
    ],
  },
  {
    categoryValue: 2,
    merchants: [
      MERCHANTS.morecontact,
      MERCHANTS.morecos,
      MERCHANTS.buyma,
      MERCHANTS.rakuten,
    ],
  },
  {
    categoryValue: 3,
    merchants: [
      MERCHANTS.sakura,
      MERCHANTS.asoview,
      MERCHANTS.hoshino,
      MERCHANTS.airtrip,
      MERCHANTS.spacemarket,
      MERCHANTS.sorahapi,
      MERCHANTS.busbookmark,
      MERCHANTS['airtrip-train'],
      MERCHANTS.travelist,
      MERCHANTS.vipliner,
      MERCHANTS['dwang-ticket'],
      MERCHANTS.tigerair,
      MERCHANTS['airtrip-hotel'],
      MERCHANTS.whitebearfamily,
    ],
  },
  {
    categoryValue: 4,
    merchants: [
      MERCHANTS.dmm,
      MERCHANTS.dlsite,
      MERCHANTS.hololive,
      MERCHANTS['pre-bandai'],
      MERCHANTS.soundhouse,
      MERCHANTS.bicamera,
      MERCHANTS.i7live,
      MERCHANTS.aviot,
      MERCHANTS.booklive,
      MERCHANTS.renta,
      MERCHANTS.orikuji,
      MERCHANTS.magi,
      MERCHANTS.rockon,
      MERCHANTS.hmvbooks,
      MERCHANTS.geo,
      MERCHANTS.yamada,
      MERCHANTS.kojima,
      MERCHANTS['dwang-ticket'],
    ],
  },
  {
    categoryValue: 5,
    merchants: [
      MERCHANTS.geo,
      MERCHANTS.rasik,
      MERCHANTS.bicamera,
      MERCHANTS.kojima,
      MERCHANTS.yamada,
      MERCHANTS.tsukumo,
      MERCHANTS.franc,
      MERCHANTS.tansu,
      MERCHANTS.iris,
      MERCHANTS.green,
      MERCHANTS.emma,
      MERCHANTS['bruno-online-store'],
      MERCHANTS.st,
      MERCHANTS['2nd-street'],
      MERCHANTS.soundhouse,
      MERCHANTS['casio-online-store'],
      MERCHANTS.aviot,
      MERCHANTS.rockon,
    ],
  },
  {
    categoryValue: 6,
    merchants: [
      MERCHANTS.kurand,
      MERCHANTS.xserver,
      MERCHANTS.geomobile,
      MERCHANTS.dlsite,
      MERCHANTS.hololive,
      MERCHANTS.asoview,
      MERCHANTS.tsukumo,
      MERCHANTS.spacemarket,
      MERCHANTS.iris,
      MERCHANTS.thesupersport,
      MERCHANTS.drmartens,
      MERCHANTS.dreamvs,
      MERCHANTS['taylor-made'],
      MERCHANTS.ships,
      MERCHANTS.orikuji,
      MERCHANTS.murasakisports,
      MERCHANTS.whitebearfamily,
    ],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: 'コスメ・カラコン' },
  { value: 3, label: '航空券・ ホテル・バス・チケット' },
  { value: 4, label: '動画・ライブ配信・ホビーグッズ' },
  { value: 5, label: '家電・インテリア雑貨' },
  { value: 6, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
