import React from 'react';
import { useMobileSystem, useAppURLsByDevice } from 'src/hooks';
import AppStoreImg from 'src/images/app-store.png';
import PlayStoreImg from 'src/images/play-store.png';
import cx from 'classnames';
import styles from './BadgesByDevice.module.scss';
import { trackAppDownload } from '../../utils';

function Badge(props) {
  const {
    src,
    alt = 'badge',
    device,
    href,
    clickArea,
    width = 160,
    height = 48,
  } = props;

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() =>
        trackAppDownload(
          device === 'Android' ? 'Google Play' : 'App Store',
          clickArea
        )
      }
    >
      <img alt={alt} src={src} width={width} height={height} />
    </a>
  );
}

const APP_URLS_SP = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/qotay44q',
  playStoreUrl: 'https://paidy.onelink.me/GTiS/qotay44q',
};

const APP_URLS_PC = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/qotay44q',
  playStoreUrl: 'https://paidy.onelink.me/KTFT/fdo8szye',
};

export default function BadgesByDevice(props) {
  const {
    className,
    appStoreImg = AppStoreImg,
    appStoreWidth = 160,
    appStoreHeight = 48,
    playStoreImg = PlayStoreImg,
    playStoreWidth = 160,
    playStoreHeight = 48,
    appUrlsSp = APP_URLS_SP,
    appUrlsPc = APP_URLS_PC,
    clickArea,
    ...restProps
  } = props;
  const { appStoreUrl, playStoreUrl } = useAppURLsByDevice(
    appUrlsSp,
    appUrlsPc
  );
  const device = useMobileSystem();

  return (
    <div className={cx(styles.badges, className)} {...restProps}>
      {['unknown', 'iOS'].includes(device) && (
        <Badge
          alt="app-store"
          src={appStoreImg}
          href={appStoreUrl}
          device="iPhone"
          clickArea={clickArea}
          width={appStoreWidth}
          height={appStoreHeight}
        />
      )}
      {['unknown', 'Android'].includes(device) && (
        <Badge
          alt="play-store"
          src={playStoreImg}
          href={playStoreUrl}
          device="Android"
          clickArea={clickArea}
          width={playStoreWidth}
          height={playStoreHeight}
        />
      )}
    </div>
  );
}
