import React from 'react';
import Img1 from 'src/images/campaign/general_cashback_202501/pay-later-1.svg';
import Img2 from 'src/images/pay-later-2.svg';
import Img3 from 'src/images/pay-later-3-27.svg';
import styles from './SeptPayLater.module.scss';

const TITLE = 'あと払いペイディとは';
const DESCRIPTION =
  'AmazonやSHEINのほか、Visaマークのあるお店ならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。';
const DATA = [
  {
    key: 1,
    img: Img1,
    text: 'スマホだけで今すぐお買い物。',
  },
  {
    key: 2,
    img: Img2,
    text: '１か月に何回お買い物をしても、お支払いはまとめて翌月でOK！',
  },
  {
    key: 3,
    img: Img3,
    text: 'お支払いは翌月27日まででOK',
  },
];

export default function JulyPayLater({
  title = TITLE,
  data = DATA,
  description = DESCRIPTION,
  id,
}) {
  return (
    <section className={styles.section} id={id}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
      <div className={styles.container}>
        {data.map((item, index) => (
          <div className={styles.item} key={item.key}>
            <img src={item.img} alt={`step-${index}`} className={styles.img} />
            <p className={styles.text}>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
