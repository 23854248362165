import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import lottie from 'lottie-web';
import styles from './Header.module.scss';
import { PAIDY_HOST } from '../../constants';

const Header = () => {
  const animationContainer = useRef(null);
  const animation = useRef(null);

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: animationContainer.current,
      loop: false,
      autoplay: true,
      path: `${PAIDY_HOST}/paidy_logo_header.lottie.json`,
    });

    return () => animation.current.destroy();
  }, []);

  return (
    <header className={classNames(styles.header, styles.headerWhite)}>
      <div
        ref={animationContainer}
        style={{ width: 128, height: 32 }}
        className={styles.logo}
      />
    </header>
  );
};

export default Header;
