import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Hidden } from '@material-ui/core';
import BannerImg from 'src/images/home-tcvm/home-banner-tcvm.jpg';
import BannerSpImg from 'src/images/home-tcvm/home-banner-tcvm-sp.jpg';
import ImgQR from 'src/images/home-tcvm/qr-tcvm.png';
import ImgAppleStore from 'src/images/badge-app-store.png';
import ImgGooglePlayStore from 'src/images/badge-play-store.png';
import useMobileSystem from 'src/hooks/useMobileSystem';
import { DYNAMICK_LINK, PAIDY_HOST } from 'src/constants';
import styles from './HomeTopBannerTCVM.module.scss';
import { trackAppDownload } from '../../utils';

const BANNER_URL = `${PAIDY_HOST}/daiseikaicampaign_202404/`;

export default function HomeTopBannerTCVM() {
  const device = useMobileSystem();

  return (
    <section className={styles.section}>
      <Hidden xsDown>
        <OutboundLink
          href={BANNER_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="ga_click_top_banner"
        >
          <img alt="banner" src={BannerImg} className={styles.banner} />
        </OutboundLink>
        <div className={styles.bottomContainer}>
          <img src={ImgQR} alt="qr" height={80} width={80} />
          <div className={styles.textAndBadgesContainer}>
            <div className={styles.badgesContainer}>
              <a
                href={DYNAMICK_LINK.APP_STORE}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackAppDownload('App Store', 'header')}
              >
                <img
                  src={ImgAppleStore}
                  alt="Apple Store"
                  width={130}
                  height={40}
                />
              </a>
              <a
                href={DYNAMICK_LINK.PLAY_STORE}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackAppDownload('Google Play', 'header')}
              >
                <img
                  src={ImgGooglePlayStore}
                  alt="Google Play Store"
                  width={130}
                  height={40}
                />
              </a>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <OutboundLink
          href={BANNER_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="ga_click_top_banner"
        >
          <img alt="banner-sp" src={BannerSpImg} className={styles.banner} />
        </OutboundLink>
        <div className={styles.bottomContainer}>
          <div className={styles.badgesContainer}>
            {device === 'iOS' && (
              <a
                href={DYNAMICK_LINK.TOP_PAGE}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackAppDownload('App Store', 'header')}
              >
                <img
                  src={ImgAppleStore}
                  alt="Apple Store"
                  width={140}
                  height={42}
                  className={styles.badge}
                />
              </a>
            )}
            {device === 'Android' && (
              <a
                href={DYNAMICK_LINK.TOP_PAGE}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackAppDownload('Google Play', 'header')}
              >
                <img
                  src={ImgGooglePlayStore}
                  alt="Google Play Store"
                  width={140}
                  height={42}
                  className={styles.badge}
                />
              </a>
            )}
          </div>
        </div>
      </Hidden>
    </section>
  );
}
